<template>
  <div>
    <div v-if="getDestinationInfoLoading" class="row-loading">
      <v-progress-circular
        indeterminate
        width="5"
        rotate="25"
        color="grey darken-4"
      ></v-progress-circular>
    </div>
    <div v-if="getDestinationInfo.text">
      <div class="container-fluid mb-12 px-8">
        <destination-filters></destination-filters>
        <results-pane></results-pane>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { capitalizeDestination } from '@/utils/destination';

export default {
  name: 'Destination',
  components: {
    DestinationFilters: () =>
      import('@/components/destination/DestinationFilters'),
    ResultsPane: () => import('@/components/ResultsPane'),
  },
  computed: {
    ...mapState('filter', ['dates']),
    ...mapGetters('filter', ['getCoordinates', 'getFilterState']),
    ...mapGetters('destination', [
      'getDestinationInfo',
      'getDestinationInfoLoading',
      'getDestinationParams',
      'getDestinationHero'
    ])
  },
  methods: {
    ...mapActions('filter', ['setTopFiltersValues']),
    ...mapActions('destination', ['setDestination']),
    ...mapActions('user', ['setUserGeoLocation'])
  },
  created() {
    navigator.geolocation.getCurrentPosition(
      position => {
        this.setUserGeoLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        });
      },
      error => {
        if (error.message == 'User denied Geolocation') {
          this.setUserGeoLocation({
            latitude: null,
            longitude: null
          });
        }
      }
    );
    const mlt = this.$route.query.mlt;

    if (mlt) {
      this.$router.push({
        ...this.$route,
        params: this.getDestinationParams,
        query: {
          checkin: this.getFilterState.dates[0],
          checkout: this.getFilterState.dates[1]
        }
      });
    } else {
      const place = this.$route.params.place;
      const country = this.$route.params.country;
      const checkin = this.$route.query.checkin || this.dates[0];
      const checkout = this.$route.query.checkout || this.dates[1];

      if (checkin && checkout) {
        this.setTopFiltersValues({
          dates: [checkin, checkout],
          guests: 1,
          destination: capitalizeDestination(`${decodeURI(place)}`)
        });
      }

      if (place && country) {
        this.setDestination({
          searchTerm: `${decodeURI(country)} ${decodeURI(place)}`,
          types: 'place'
        }).then(newRoute => {
          if (newRoute) {
            this.$router.push(newRoute);
          }
        });
      }
    }
  }
};
</script>
